import styled from 'styled-components';
import Thesocial from '../components/Home/Thesocial';
import socialdata from '../Json/socialdata.json';
import React from 'react'
import Layout from "../components/layout"
import Seo from "../components/seo"

const SectionSocialGroup = styled.div`
  margin: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1em;
  justify-items: center;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }
`;

export default function SocialPage() {
  return (
    <Layout>
    <Seo title="SocialContent" />
    <div className="Hero">
      <div className="HeroGroup">
      <h2>Social Media Content</h2>
        <SectionSocialGroup>
          {socialdata.socials.map((thesocial) => (
            <Thesocial
              title={thesocial.title}
              image={thesocial.image}
              text={thesocial.text}
              url={thesocial.url}
            />
          ))}
        </SectionSocialGroup>
      </div>
    </div>
    </Layout>
  );
}
